import React from "react"
import { graphql } from "gatsby"

import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function DeliveryPageAlt({ data }) {
  const content = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.markdownRemark.frontmatter.content)
    .toString()

  return (
    <Layout pageInfo={{ pageName: "Delivery" }}>
      <SEO title="Delivery" />
      <div>
        <div className="fw banner-inner straw-banner-inner">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1>Delivery</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="fw warm-grey-bg sect-pad-top sect-pad-bot">
          <div className="container main-content">
            <div className="row">
              <div className="col-sm-12 col-md-10 offset-md-1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query DeliveryPageAltQuery {
    markdownRemark(fileAbsolutePath: { regex: "/delivery.md/" }) {
      html
      frontmatter {
        content
      }
    }
  }
`
